import React, { useEffect } from 'react';
import Menu from '../../../components/Painel/Menu';
import './styles.css';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import historico from '../../../services/Historico';
import Dashboard from '../../../components/Painel/Dashboard';
import CadastrarRede from '../../../components/Painel/CadastrarRede';
import CadastrarAdministrador from '../../../components/Painel/CadastrarAdministrador';
import Perfil from '../../../components/Painel/Perfil';
import CadastrarRevenda from '../../../components/Painel/CadastrarRevenda';
import MenuCadastrarProduto from '../../../components/Painel/MenuCadastrarProduto';
import CadastrarProduto from '../../../components/Painel/CadastrarProduto';
import CadastrarProdutoRevenda from '../../../components/Painel/CadastrarProdutoRevenda';
import CadastrarParceiro from '../../../components/Painel/CadastrarParceiro';
import CadastrarPesquisaMercado from '../../../components/Painel/CadastrarPesquisaMercado';
import MenuUploadProduto from '../../../components/Painel/MenuUploadProduto';
import UploadProduto from '../../../components/Painel/UploadProduto';
import UploadProdutoRevenda from '../../../components/Painel/UploadProdutoRevenda';
import UploadVendas from '../../../components/Painel/UploadVendas';
import Redes from '../../../components/Painel/Redes';
import Revendas from '../../../components/Painel/Revendas';
import ProdutosCriteria from '../../../components/Painel/ProdutosCriteria';
import Parceiros from '../../../components/Painel/Parceiros';
import Pesquisas from '../../../components/Painel/Pesquisas';
import Pontuacoes from '../../../components/Painel/Pontuacoes';
import DetalheParceiro from '../../../components/Painel/DetalheParceiro';
import DetalheRevenda from '../../../components/Painel/DetalheRevenda';
import PontuacoesPendente from '../../../components/Painel/PontuacoesPendente';
import NovaVenda from '../../../components/Painel/NovaVenda';
import Resgates from '../../../components/Painel/Resgates';
import UploadHistorico from '../../../components/Painel/UploadHistorico/index';
import PontuacoesEncerradas from '../../../components/Painel/PontuacoesEncerradas/index';
import Mensuracao from '../../../components/Painel/Mensuracao/index';
import TaxaSucesso from '../../../components/Painel/TaxaSucesso/index';
import Relatorio from '../../../components/Painel/Relatorio/index';
import Antifraude from '../../../components/Painel/Antifraude/index';
import Historico from '../../../components/Painel/Historico/index';
import Cartoes from '../../../components/Painel/Cartoes/index';
import UploadSaldoCartao from '../../../components/Painel/UploadSaldoCartao/index';
import ProdutosVendidos from '../../../components/Painel/ProdutosVendidos/index';
import AtualizarRelatorio from '../../../components/Painel/AtualizarRelatorio/index';
import CorrecaoPontuacao from '../../../components/Painel/CorrecaoPontuacao/index';
import UploadMidia from '../../../components/Painel/UploadMidia/index';
import Midias from '../../../components/Painel/Midias/index';
import ComprasPontuacao from '../../../components/Painel/ComprasPontuacao/index';
import Premios from '../../../components/Painel/Premios/index';
import CadastrarPremio from '../../../components/Painel/CadastrarPremio/index';
import CadastrarCartao from '../../../components/Painel/CadastrarCartao/index';
import Logs from '../../../components/Painel/Logs/index';
import ConfiguracoesWhats from '../../../components/Painel/ConfiguracoesWhats';
import itens from '../../../components/Painel/Menu/itens';
import Pontos from '../../../components/Painel/Pontos';
import { useSelector } from 'react-redux';

const getPath = (item, path = []) => {
  if (item.subItens) {
    item.subItens.forEach((i) => {
      getPath(
        {
          subItens: i.subItens,
          destino: item.destino + i.destino,
          permissao: i.permissao,
        },
        path
      );
    });
  } else {
    path.push({
      destino: item.destino,
      permissao: item.permissao,
    });
  }
  return path;
};

const paths = [];

itens.forEach((item) => {
  paths.push(...getPath(item));
});

export default function Home() {
  let match = useRouteMatch();

  const usuario = useSelector((state) => {
    return state.usuario;
  });

  useEffect(() => {
    document.title = 'Clube Gaya - Painel';
  }, []);

  const routes = [
    {
      path: `${match.path}/dashboard`,
      component: Dashboard,
    },
    {
      path: `${match.path}/perfil`,
      component: Perfil,
    },
    {
      path: `${match.path}/cadastrar/rede`,
      component: CadastrarRede,
    },
    {
      path: `${match.path}/cadastrar/administrador`,
      component: CadastrarAdministrador,
    },
    {
      path: `${match.path}/cadastrar/revenda`,
      component: CadastrarRevenda,
    },
    {
      path: `${match.path}/cadastrar/produto`,
      component: MenuCadastrarProduto,
    },
    {
      path: `${match.path}/cadastrar/produto/criteria`,
      component: CadastrarProduto,
    },
    {
      path: `${match.path}/cadastrar/produto/revenda`,
      component: CadastrarProdutoRevenda,
    },
    {
      path: `${match.path}/cadastrar/parceiro`,
      component: CadastrarParceiro,
    },
    {
      path: `${match.path}/cadastrar/cartao`,
      component: CadastrarCartao,
    },
    {
      path: `${match.path}/cadastrar/pesquisa-mercado`,
      component: CadastrarPesquisaMercado,
    },
    {
      path: `${match.path}/processar/produtos`,
      component: MenuUploadProduto,
    },
    {
      path: `${match.path}/processar/produtos/criteria`,
      component: UploadProduto,
    },
    {
      path: `${match.path}/processar/produtos/revenda`,
      component: UploadProdutoRevenda,
    },
    {
      path: `${match.path}/processar/vendas`,
      component: UploadVendas,
    },
    {
      path: `${match.path}/processar/historico`,
      component: UploadHistorico,
    },
    {
      path: `${match.path}/processar/pontos`,
      component: Pontos,
    },
    { path: `${match.path}/redes`, component: Redes },
    {
      path: `${match.path}/logs`,
      component: Logs,
    },
    {
      path: `${match.path}/Lojas`,
      component: Revendas,
    },
    {
      path: `${match.path}/produtos`,
      component: ProdutosCriteria,
    },
    {
      path: `${match.path}/vendedores`,
      component: Parceiros,
    },
    {
      path: `${match.path}/pesquisa-de-mercado`,
      component: Pesquisas,
    },
    {
      path: `${match.path}/pontuacoes/pontuado`,
      component: Pontuacoes,
    },
    {
      path: `${match.path}/pontuacoes/encerrado`,
      component: PontuacoesEncerradas,
    },
    {
      path: `${match.path}/parceiro/:cpf`,
      component: DetalheParceiro,
    },
    {
      path: `${match.path}/revenda/:cnpj`,
      component: DetalheRevenda,
    },
    {
      path: `${match.path}/processar/pontuacoes`,
      component: PontuacoesPendente,
    },
    {
      path: `${match.path}/processar/nova-venda`,
      component: NovaVenda,
    },
    {
      path: `${match.path}/processar/relatorios`,
      component: AtualizarRelatorio,
    },
    {
      path: `${match.path}/processar/correcao-pontuacao`,
      component: CorrecaoPontuacao,
    },
    {
      path: `${match.path}/resgates`,
      component: Resgates,
    },
    {
      path: `${match.path}/mensuracao`,
      component: Mensuracao,
    },
    {
      path: `${match.path}/taxa-sucesso`,
      component: TaxaSucesso,
    },
    {
      path: `${match.path}/relatorio`,
      component: Relatorio,
    },
    {
      path: `${match.path}/antifraude`,
      component: Antifraude,
    },
    {
      path: `${match.path}/historico`,
      component: Historico,
    },
    {
      path: `${match.path}/cartoes`,
      component: Cartoes,
    },
    {
      path: `${match.path}/processar/saldo-cartao`,
      component: UploadSaldoCartao,
    },
    {
      path: `${match.path}/produtos-vendidos`,
      component: ProdutosVendidos,
    },
    {
      path: `${match.path}/processar/foto-video`,
      component: UploadMidia,
    },
    {
      path: `${match.path}/fotos-videos`,
      component: Midias,
    },
    {
      path: `${match.path}/compras-pontuacao`,
      component: ComprasPontuacao,
    },
    {
      path: `${match.path}/premios`,
      component: Premios,
    },
    {
      path: `${match.path}/cadastrar/premio`,
      component: CadastrarPremio,
    },
    {
      path: `${match.path}/configuracao-whats`,
      component: ConfiguracoesWhats,
    },
  ];

  const allowedRoutes = routes.filter((route) => {
    const path = paths.find((p) => route.path === match.path + p.destino);
    if (!path) return true;
    return path.permissao.includes(usuario.permissao);
  });

  return (
    <>
      <Menu
        selecionarItem={(item) => {
          if (historico.location.pathname !== `/${item}`) {
            historico.push(`${match.path}${item}`);
          }
        }}
      />
      <main className="main-painel">
        <Switch>
          {allowedRoutes.map((route, index) => (
            <Route
              exact
              key={index}
              path={route.path}
              component={route.component}
            />
          ))}
        </Switch>
      </main>
    </>
  );
}
